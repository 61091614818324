import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const HelpRepaymentPage = () => (
    <Layout>
        <Seo title={'Help With Repayments For Our Customers | Nemo Personal Finance'} description={'If you are a customer who is having difficulty making their repayments and want to know what both you and Nemo can do to help the situation, click here.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/help-with-repayments/dealing-with-difficult-times">Dealing
                            with difficult times</a></li>
                        <li><a href="/help-with-repayments/useful-information">Useful
                            information</a></li>
                        <li><a href="/help-with-repayments/budget-planner-intro">Budget
                            Planner</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Help with repayments</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading">At Nemo, we understand that things can change, and this can sometimes
                            make it difficult for you to make your payments. It’s important that you are aware that your
                            Nemo second charge mortgage is secured against your property, and so if you do come up
                            against any difficulties, your monthly second charge mortgage repayments must be
                            prioritised.</p>
                        If you are experiencing financial difficulties or have any concerns about your future ability to
                        make payments, please talk to us. We have a dedicated team who are on hand to help you. Just
                        take that first step, call us and tell us what's making it difficult for you so that we can work
                        together to find the most practical solution with you.
                        <div><br /></div>
                        <div>If you are currently up to date but foresee a problem with future payments, call us
                            on&nbsp;<span className="telnum"><strong>0800 612 1008</strong></span></div>
                        <div><br /></div>
                        <div>If you have already missed a payment, call us on&nbsp;<span className="telnum"><strong>0800 019 7415</strong></span>
                        </div>
                        <div><br /></div>
                        <div><strong>What can Nemo do to help me?</strong></div>
                        <div>We've been able to help many of our customers through challenging times. So whether your
                            financial difficulties are short term or long term, as a result of being off work sick, out
                            of work, struggling through a relationship split, money mismanagement or any other reason,
                            please contact us as soon as possible on&nbsp;<span
                                className="telnum"><strong>0800 019 7415</strong></span></div>
                        <div><br /></div>
                        <div>There are a number of solutions that we can potentially offer you which are dependent upon
                            your individual circumstances and your willingness to be part of the solution. The starting
                            point will be for you to complete a Budget Planner for us - this is the simplest way for us
                            to understand your current financial situation. Using the Budget Planner information, we can
                            look to work out a plan to get you back on track.
                        </div>
                        <div><br /></div>
                        <div><strong>Don't ignore it</strong></div>
                        <div>Don't ignore your debt problem. Missing repayments can seriously damage your ability to get
                            credit in the future. Your credit rating may be checked by companies to see how well you
                            have managed your credit in the past, whether you want a mortgage or finance for a fridge,
                            you may end up unable to buy goods on credit or get a mortgage if you miss repayments on
                            your credit commitments.
                        </div>
                        <div><br /></div>
                        <div>If you've missed payments on your second charge mortgage from Nemo, please call our
                            Customer Management team on&nbsp;<span
                                className="telnum"><strong>0800 019 7415</strong></span></div>
                    </div>
                </div>
                <div className="fbCol">
                    <div className="fbList">
                        <ul>
                            <li className="">
                                <div className="top"></div>
                                <div className="txtWrap">
                                    <h3><span style={{'display': 'none'}}>&nbsp;</span></h3>
                                    <p>
                                    </p><p>YOUR HOME MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A MORTGAGE
                                    OR ANY OTHER DEBT SECURED ON IT.</p>
                                    <p></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
)

export default HelpRepaymentPage;